import _extends from "@babel/runtime/helpers/extends";
/* eslint-disable no-empty */
import { useEffect, useState, useRef } from 'react';
import { getMonaco } from './monaco';

// @todo fill type def for monaco editor without refering monaco editor
/**
 * @see https://microsoft.github.io/monaco-editor/api/index.html
 */
var CURRENT_LANGUAGE = (window.locale || window.localStorage.getItem('vdev-locale') || '').replace(/_/, '-') || 'en-US';
export var WORD_EDITOR_INITIALIZING = CURRENT_LANGUAGE === 'en-US' ? 'Initializing Editor' : 'Khởi tạo trình thiết kế';
export var INITIAL_OPTIONS = {
  fontSize: 12,
  tabSize: 2,
  fontFamily: 'Menlo, Monaco, Courier New, monospace',
  folding: true,
  minimap: {
    enabled: false
  },
  autoIndent: 'advanced',
  contextmenu: true,
  useTabStops: true,
  wordBasedSuggestions: true,
  formatOnPaste: true,
  automaticLayout: true,
  lineNumbers: 'on',
  wordWrap: 'off',
  scrollBeyondLastLine: false,
  fixedOverflowWidgets: false,
  snippetSuggestions: 'top',
  scrollbar: {
    vertical: 'auto',
    horizontal: 'auto',
    verticalScrollbarSize: 10,
    horizontalScrollbarSize: 10
  }
};
var DIFF_EDITOR_INITIAL_OPTIONS = {
  fontSize: 12,
  fontFamily: 'Menlo, Monaco, Courier New, monospace',
  folding: true,
  minimap: {
    enabled: false
  },
  autoIndent: 'advanced',
  contextmenu: true,
  useTabStops: true,
  formatOnPaste: true,
  automaticLayout: true,
  lineNumbers: 'on',
  wordWrap: 'off',
  scrollBeyondLastLine: false,
  fixedOverflowWidgets: false,
  snippetSuggestions: 'top',
  scrollbar: {
    vertical: 'auto',
    horizontal: 'auto',
    verticalScrollbarSize: 10,
    horizontalScrollbarSize: 10
  }
};
export var useEditor = function useEditor(type, props) {
  var editorDidMount = props.editorDidMount,
    editorWillMount = props.editorWillMount,
    theme = props.theme,
    value = props.value,
    path = props.path,
    language = props.language,
    saveViewState = props.saveViewState,
    defaultValue = props.defaultValue,
    enhancers = props.enhancers;
  var _useState = useState(false),
    isEditorReady = _useState[0],
    setIsEditorReady = _useState[1];
  var _useState2 = useState(false),
    focused = _useState2[0],
    setFocused = _useState2[1];
  var _useState3 = useState(false),
    loading = _useState3[0],
    setLoading = _useState3[1];
  var defaultValueRef = useRef(defaultValue);
  var valueRef = useRef(value);
  var languageRef = useRef(language || 'text');
  var pathRef = useRef(path);
  var previousPath = usePrevious(path);
  var requireConfigRef = useRef(props.requireConfig);
  var optionRef = useRef(props.options);
  var monacoRef = useRef();
  var editorRef = useRef();
  var containerRef = useRef();
  var typeRef = useRef(type);
  var editorDidMountRef = useRef();
  var editorWillMountRef = useRef();
  var decomposeRef = useRef(false);
  var viewStatusRef = useRef(new Map());
  var enhancersRef = useRef({});
  useEffect(function () {
    enhancersRef.current.enhancers = enhancers;
  }, [enhancers]);
  useEffect(function () {
    editorDidMountRef.current = editorDidMount;
  }, [editorDidMount]);
  useEffect(function () {
    editorWillMountRef.current = editorWillMount;
  }, [editorWillMount]);
  useEffect(function () {
    valueRef.current = value;
  }, [value]);
  useEffect(function () {
    languageRef.current = language;
  }, [language]);
  useEffect(function () {
    defaultValueRef.current = defaultValue;
  }, [defaultValue]);

  // make sure loader / editor only init once
  useEffect(function () {
    setLoading(true);
    getMonaco(requireConfigRef.current).then(function (monaco) {
      var _enhancersRef$current;
      window.MonacoEnvironment = undefined;
      if (typeof window.define === 'function' && window.define.amd) {
        // make monaco-editor's loader work with webpack's umd loader
        // @see https://github.com/microsoft/monaco-editor/issues/2283
        delete window.define.amd;
      }
      monacoRef.current = monaco;
      try {
        var _editorWillMountRef$c;
        (_editorWillMountRef$c = editorWillMountRef.current) === null || _editorWillMountRef$c === void 0 ? void 0 : _editorWillMountRef$c.call(editorWillMountRef, monaco);
      } catch (err) {}
      if (!containerRef.current) {
        return;
      }
      var editor;
      if (typeRef.current === 'single') {
        var _ref, _valueRef$current;
        var model = getOrCreateModel(monaco, (_ref = (_valueRef$current = valueRef.current) !== null && _valueRef$current !== void 0 ? _valueRef$current : defaultValueRef.current) !== null && _ref !== void 0 ? _ref : '', languageRef.current, pathRef.current);
        editor = monaco.editor.create(containerRef.current, _extends({
          automaticLayout: true
        }, INITIAL_OPTIONS, optionRef.current));
        editor.setModel(model);
      } else {
        var originalModel = monaco.editor.createModel(valueRef.current, languageRef.current);
        var modifiedModel = monaco.editor.createModel(valueRef.current, languageRef.current);
        editor = monaco.editor.createDiffEditor(containerRef.current, _extends({
          automaticLayout: true
        }, DIFF_EDITOR_INITIAL_OPTIONS, optionRef.current));
        editor.setModel({
          original: originalModel,
          modified: modifiedModel
        });
      }
      editorRef.current = editor;
      (_enhancersRef$current = enhancersRef.current.enhancers) === null || _enhancersRef$current === void 0 ? void 0 : _enhancersRef$current.forEach(function (en) {
        return en(monaco, editor);
      });
      try {
        var _editorDidMountRef$cu;
        (_editorDidMountRef$cu = editorDidMountRef.current) === null || _editorDidMountRef$cu === void 0 ? void 0 : _editorDidMountRef$cu.call(editorDidMountRef, monaco, editor);
      } catch (err) {}
      !decomposeRef.current && setIsEditorReady(true);
    })["catch"](function (err) {
      // eslint-disable-next-line no-console
      console.error('Monaco Editor Load Error!', err);
    }).then(function () {
      !decomposeRef.current && setLoading(false);
    });
  }, []);
  useEffect(function () {
    if (!isEditorReady) {
      return;
    }
    monacoRef.current.editor.setTheme(theme);
  }, [isEditorReady, theme]);

  // focus status
  useEffect(function () {
    if (!isEditorReady) {
      return;
    }
    var editor = type === 'diff' ? editorRef.current.getModifiedEditor() : editorRef.current;
    editor === null || editor === void 0 ? void 0 : editor.onDidFocusEditorText(function () {
      !decomposeRef.current && setFocused(true);
    });
    editor === null || editor === void 0 ? void 0 : editor.onDidBlurEditorText(function () {
      !decomposeRef.current && setFocused(false);
    });
  }, [isEditorReady, type]);

  // decomposing status
  useEffect(function () {
    return function () {
      decomposeRef.current = true;
    };
  }, []);

  // controlled value -- diff mode / without path only
  useEffect(function () {
    var _ref2, _editor$getOption, _monacoRef$current;
    if (!isEditorReady) {
      return;
    }
    if (type !== 'diff' && !!path) {
      return;
    }
    var editor = type === 'diff' ? editorRef.current.getModifiedEditor() : editorRef.current;
    var nextValue = (_ref2 = value !== null && value !== void 0 ? value : defaultValueRef.current) !== null && _ref2 !== void 0 ? _ref2 : '';
    if (editor !== null && editor !== void 0 && (_editor$getOption = editor.getOption) !== null && _editor$getOption !== void 0 && _editor$getOption.call(editor, (_monacoRef$current = monacoRef.current) === null || _monacoRef$current === void 0 ? void 0 : _monacoRef$current.editor.EditorOption.readOnly)) {
      editor === null || editor === void 0 ? void 0 : editor.setValue(nextValue);
    } else if (value !== (editor === null || editor === void 0 ? void 0 : editor.getValue())) {
      editor === null || editor === void 0 ? void 0 : editor.executeEdits('', [{
        range: editor === null || editor === void 0 ? void 0 : editor.getModel().getFullModelRange(),
        text: nextValue,
        forceMoveMarkers: true
      }]);
      editor === null || editor === void 0 ? void 0 : editor.pushUndoStop();
    }
  }, [isEditorReady, path, type, value]);

  // multi-model && controlled value (shouldn't be diff mode)
  useEffect(function () {
    var _valueRef$current2;
    if (!isEditorReady) {
      return;
    }
    if (type === 'diff') {
      return;
    }
    if (path === previousPath) {
      return;
    }
    var model = getOrCreateModel(monacoRef.current, (_valueRef$current2 = valueRef.current) !== null && _valueRef$current2 !== void 0 ? _valueRef$current2 : defaultValueRef.current, languageRef.current, path);
    var editor = editorRef.current;
    if (valueRef.current !== null && valueRef.current !== undefined && model.getValue() !== valueRef.current) {
      model.setValue(valueRef.current);
    }
    if (model !== editorRef.current.getModel()) {
      saveViewState && viewStatusRef.current.set(previousPath, editor.saveViewState());
      editor.setModel(model);
      saveViewState && editor.restoreViewState(viewStatusRef.current.get(path));
    }
  }, [isEditorReady, value, path, previousPath, type]);
  var retEditorRef = editorRef;
  return {
    isEditorReady: isEditorReady,
    focused: focused,
    loading: loading,
    containerRef: containerRef,
    monacoRef: monacoRef,
    editorRef: retEditorRef,
    valueRef: valueRef
  };
};
function getOrCreateModel(monaco, value, language, path) {
  if (path) {
    var prevModel = monaco.editor.getModel(monaco.Uri.parse(path));
    if (prevModel) {
      return prevModel;
    }
  }
  return monaco.editor.createModel(value, language, path && monaco.Uri.parse(path));
}
function usePrevious(value) {
  var ref = useRef();
  useEffect(function () {
    ref.current = value;
  }, [value]);
  return ref.current;
}