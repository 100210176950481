// eslint-disable-next-line no-redeclare
export var AutoFit = '100%';

/**
 * 模拟器控制进程协议
 */

export function isSimulatorHost(obj) {
  return obj && obj.isSimulator;
}

/**
 * 组件类定义
 */

/**
 * 组件实例定义
 */