// 转换成一个 .jsx 文件内 React Class 类 render 函数返回的 jsx 代码
/**
 * 搭建基础协议 - 单个组件树节点描述
 */
export function isDOMText(data) {
  return typeof data === 'string';
}

/**
 * 容器结构描述
 */

/**
 * 页面容器
 * @see https://lowcode-engine.cn/lowcode
 */

/**
 * 低代码业务组件容器
 * @see https://lowcode-engine.cn/lowcode
 */

/**
 * 区块容器
 * @see https://lowcode-engine.cn/lowcode
 */

/**
 * @todo
 */

/**
 * Slot schema 描述
 */

/**
 * 应用描述
 */

export function isNodeSchema(data) {
  return data && data.componentName;
}
export function isProjectSchema(data) {
  return data && data.componentsTree;
}